import trace from '../lib/trace';
import config from '~/configuration';
import auth from '~/lib/authentication';

const {
  privateApiRoot,
  publicApiRoot,
  signalRHubUrl,
  functionHostKey,
  publicFunctionHostKey,
} = config;

const getPrivateResponse = async (url, options) => {
  const headers = {};
  for (let entry in options?.headers ?? {}) {
    headers[entry.toLowerCase()] = options.headers[entry];
  }

  const token = await auth.getAutoRefreshAccessToken();

  if (token === null) {
    return new Response(null, {
      status: 401,
      statusText: 'Unauthorized',
    });
  }

  headers['authorization'] = `Bearer ${token}`;
  headers['x-functions-key'] = functionHostKey;

  return fetch(url, {
    ...(options ?? {}),
    headers,
  });
};

const getPublicResponse = async (url, options) => {
  const headers = {};
  for (let entry in options?.headers ?? {}) {
    headers[entry.toLowerCase()] = options.headers[entry];
  }

  headers['x-functions-key'] = publicFunctionHostKey;

  return fetch(url, {
    ...(options ?? {}),
    headers,
  });
};

const fetchHandler = async (url, options) => {
  if (url.indexOf(privateApiRoot) === 0 || url.indexOf(signalRHubUrl) === 0) {
    return await getPrivateResponse(url, options);
  }

  if (url.indexOf(publicApiRoot) === 0) {
    return await getPublicResponse(url, options);
  }

  return await fetch(url, options);
};

const wrappedFetch = async (url, options) => {
  const start = Date.now();
  const result = await fetchHandler(url, options);
  const diff = Date.now() - start;
  trace(url, result.status, `${diff}ms`);

  return result;
};

export {wrappedFetch as fetch};

export const throttled = (func) => {
  let pending;
  return async (...args) => {
    pending?.abort('Pending call canceled');

    pending = new AbortController();
    const signal = pending.signal;
    try {
      await new Promise((resolve) => setTimeout(resolve, 250));
      if (signal.aborted) {
        return;
      }

      return await func(signal, ...args);
    } finally {
      if (signal.aborted) {
        return;
      }

      pending = null;
    }
  };
};
