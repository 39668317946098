import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';

const articles = {
  details: async (license, dealer_id, article_id) => {
    const result = await fetch(`${endpoints.article}/${license}`, {
      method: 'POST',
      body: JSON.stringify({
        dealer_id,
        article_id,
      }),
    });

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },
  categories: async (license) => {
    const result = await fetch(`${endpoints.articles}/categories/${license}`);

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },

  list: async (license, category) => {
    const result = await fetch(
      `${endpoints.articles}/category/${category.categoryId}/${license}`,
    );

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },

  search_by_item_mp_ids: async (
    license,
    request_id,
    dealer_id,
    item_mp_ids,
  ) => {
    const result = await fetch(`${endpoints.articles}/${license}`, {
      method: 'POST',
      body: JSON.stringify({
        request_id,
        dealer_id,
        item_mp_ids,
      }),
    });

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },

  search_by_gen_arts: async (license, dealer_id, gen_arts) => {
    const result = await fetch(`${endpoints.articles}/${license}`, {
      method: 'POST',
      body: JSON.stringify({
        dealer_id,
        gen_arts,
      }),
    });

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },

  search: async (license, prefix) => {
    const result = await fetch(`${endpoints.articles}/${license}/search`, {
      method: 'POST',
      body: JSON.stringify({
        prefix,
      }),
    });

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },

  details_link: async (license, dealer_id, article_number) => {
    const result = await fetch(`${endpoints.article}/${license}`, {
      method: 'POST',
      body: JSON.stringify({
        dealer_id,
        article_number,
      }),
    });

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },
};

export default articles;
