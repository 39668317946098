import React from 'react';

import sy from '~/styles';
import {Link} from '~/components/controls';
import {articles as articlesApi} from '~/api/private';

const VrooamDetailsLink = ({license, dealer_id, article}) => {
  const onLinkPress = async () => {
    const result = await articlesApi.details_link(
      license,
      dealer_id,
      article.number,
    );
    if (!result.success) {
      return;
    }

    window.open(result.link, '_blank');
  };

  return (
    <>
      <Link textStyle={[sy.small, sy['text-lightgray']]} onPress={onLinkPress}>
        {article.source}&nbsp;&middot;&nbsp;{article.number}
      </Link>
    </>
  );
};

export default VrooamDetailsLink;
