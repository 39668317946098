import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import moment from 'moment';
import _ from 'lodash';

import styles from './styles';
import sy from '~/styles';
import {
  Pressable,
  Text,
  ServiceRequestStatusIcon,
  ReplacementTileIcon,
  UserLabel,
} from '~/components/controls';
import {SERVICE_TYPE_CODES} from '~/types/services';
import Format from '~/lib/format';
import {service_request as serviceRequestApi} from '~/api/private';
import {IsReplacementVehicle} from '~/types/request';

import CarRepairIcon from '~/images/md-icons/car_repair/materialicons/24px.svg';
import ApprovalIcon from '~/images/md-icons/approval/materialicons/24px.svg';
import TireRepairIcon from '~/images/md-icons/tire_repair/materialicons/24px.svg';
import BuildIcon from '~/images/md-icons/build_repair/materialicons/24px.svg';
import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';

const Type = ({type, style}) => {
  if (!type) {
    return null;
  }

  let icon;
  switch (type) {
    case SERVICE_TYPE_CODES.Maintenance:
      icon = <CarRepairIcon fill="#828282" style={style} />;
      break;
    case SERVICE_TYPE_CODES.Inspection:
      icon = <ApprovalIcon fill="#828282" style={style} />;
      break;
    case SERVICE_TYPE_CODES.Tires:
      icon = <TireRepairIcon fill="#828282" style={style} />;
      break;
    default:
      icon = <BuildIcon fill="#828282" style={style} />;
      break;
  }

  return icon;
};

const Tile = ({
  style,
  id,
  datetime,
  status,
  rob_status,
  car_name,
  license,
  lessor,
  kvk_name,
  request_type,
  parent_id,
  replacement,
  assigned_user_id,
}) => {
  const navigation = useNavigation();
  const [duration, setDuration] = useState(null);
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    if (IsReplacementVehicle(request_type)) {
      return;
    }

    const fetch = async () => {
      const {result} = await serviceRequestApi.calendar_info(id);
      setDuration(result?.duration);
      setIcons(result?.icons);
    };

    fetch();
  }, [id, status, rob_status, request_type]);

  return (
    <Pressable
      style={[styles.root, style]}
      onPress={() => {
        if (IsReplacementVehicle(request_type)) {
          navigation.navigate('Replacement', {request_id: parent_id});
        } else {
          navigation.navigate('Request', {request_id: id});
        }
      }}>
      <View style={{width: 52}}>
        <Text style={sy.smallRegular}>
          {moment.utc(datetime).format('H:mm')}
        </Text>
        {_.isNumber(duration) && (
          <Text style={[sy.smallRegular, , {lineHeight: 20}]}>
            {Format.number(duration, 2, false, {
              minimumFractionDigits: 0,
            })}
            &nbsp;u
          </Text>
        )}
      </View>
      <View>
        {IsReplacementVehicle(request_type) ? (
          <DirectionsCarIcon fill="#4A4A49" width={24} height={24} />
        ) : (
          <ServiceRequestStatusIcon status={status} />
        )}
      </View>
      <View style={{flex: 1, marginHorizontal: 16}}>
        <Text style={[sy.mediumPlus, sy.truncate]}>
          {Format.license(license)}
        </Text>
        <View style={[sy['flex-row']]}>
          <Text style={[sy.smallRegular, sy.truncate, {lineHeight: 20}]}>
            {car_name}
          </Text>
        </View>
        <View style={[sy['flex-row']]}>
          <Text style={[sy.smallRegular, sy.truncate, {lineHeight: 20}]}>
            {kvk_name ?? lessor}
          </Text>
        </View>
      </View>
      <View style={{flex: 0, minWidth: 'auto', alignItems: 'flex-end'}}>
        <View style={[sy['flex-row'], sy['gap-2']]}>
          {IsReplacementVehicle(request_type) && (
            <ServiceRequestStatusIcon
              status={status}
              iconProps={{height: 18, width: 18}}
            />
          )}
          {icons.map((icon) => (
            <Type key={icon} type={icon} style={{height: 18, width: 18}} />
          ))}
          {!IsReplacementVehicle(request_type) && replacement?.type && (
            <ReplacementTileIcon
              request_id={id}
              type={replacement?.type}
              fill="#828282"
              style={{height: 18, width: 18}}
            />
          )}
        </View>
      </View>
      <UserLabel key={id} user_id={assigned_user_id} />
    </Pressable>
  );
};

export default Tile;
