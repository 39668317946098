export const SOURCES = {
  Rob: 'ROB-Net',
  TecRMI: 'TecRMI',
  Fource: 'Fource',
  PartsPoint: 'PartsPoint',
  Custom: 'Invoer',
  SlaTasks: 'Bahnkick activiteiten',
  Make: 'Fabrikant',

  // Vrooam sources
  Koskamp: 'Koskamp',
  Keulen: 'Keulen',
  Broekhuizen: 'Broekhuizen',
  AUTOINDUS: 'AUTOINDUS',

  Vrooam: (source) => {
    switch (source) {
      case 'Koskamp':
      case 'Keulen':
      case 'Broekhuizen':
      case 'AUTOINDUS':
        return true;
    }

    return false;
  },
};
