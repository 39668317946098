import {StaticDispatch, StaticState} from '~/components/hoc/with-session-state';
import {navigate} from '~/lib/root-navigation';
import {
  serviceRequestUpdate,
  serviceRequestInsert,
  updateImportantMessage,
  setUpdateAfter,
  showRefreshImportantDialog,
  showRefreshDialog,
} from '~/actions';

const showNotification = (request_id, title, body) => {
  const onClick = (event) => {
    event?.preventDefault();
    window.focus();
    navigate('Request', {request_id});
  };

  if (window.electronApi) {
    try {
      window.electronApi.showNotification?.(title, body).then(onClick);
    } catch {}
  } else {
    const notification = new Notification(title, {
      body,
      tag: request_id.replace(/-/g, '').substring(0, 31),
    });

    notification.onclick = onClick;
  }
};

const handler = ({
  alerts,
  errors,
  request_id,
  lessor,
  license,
  status,
  reason,
  rob_id,
  rob_status,
  rob_invoicing_status,
}) => {
  console.log(
    'Notification received',
    alerts,
    errors,
    request_id,
    lessor,
    license,
    status,
    reason,
    rob_id,
    rob_status,
    rob_invoicing_status,
  );

  if (request_id) {
    const {user_settings} = StaticState ?? {};
    const {push_notifications} = user_settings ?? {};

    if (push_notifications?.message && status === 'Message') {
      showNotification(request_id, lessor, `Nieuw bericht voor de ${license}`);
    } else if (status === 'Draft' || status === 'Reserved') {
      StaticDispatch(serviceRequestInsert());
    } else {
      if (push_notifications?.approved && status === 'Approved') {
        showNotification(request_id, lessor, `Goedkeuring voor de ${license}`);
      } else if (push_notifications?.denied && status === 'Denied') {
        showNotification(request_id, lessor, `Afkeuring voor de ${license}`);
      }

      StaticDispatch(
        serviceRequestUpdate({
          request_id,
          status,
          reason,
          rob_id,
          rob_status,
          rob_invoicing_status,
        }),
      );
    }
  }

  if (alerts) {
    for (const alert of alerts) {
      if (alert.type === 'REFRESH_IMPORTANT') {
        StaticDispatch(setUpdateAfter());
        StaticDispatch(showRefreshImportantDialog());
      }

      if (alert.type === 'REFRESH') {
        StaticDispatch(showRefreshDialog());
      }

      if (alert.type === 'IMPORTANT') {
        StaticDispatch(updateImportantMessage());
      }
    }
  }
};

export default handler;
